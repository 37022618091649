import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const Process = makeShortcode("Process");
const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const Link = makeShortcode("Link");
const InfoBlock = makeShortcode("InfoBlock");
const Box = makeShortcode("Box");
const Aligner = makeShortcode("Aligner");
const Button = makeShortcode("Button");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "banking-in-the-shadows",
      "style": {
        "position": "relative"
      }
    }}>{`Banking in the Shadows`}<a parentName="h1" {...{
        "href": "#banking-in-the-shadows",
        "aria-label": "banking in the shadows permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <Video src="https://www.youtube-nocookie.com/embed/videoseries?list=PLmtuEaMvhDZbVJfl0Se-1eQncA_2ZB4bs&index=2" mdxType="Video" />
    <p>{`We have covered the two major stories about the development of money, global trade and finance:
collectibles and debt. It's now time to look in greater detail at money as it actually works in
the modern economy. And it's really difficult to do this, because any classical economics
textbook - even from the 1960's - is already outdated. `}</p>
    <p>{`The best resource we know, which is able to take both a long, historical perspective, but then
ground it in the day-to-day realities of money and banking as they actually work in our era, is
this course from Perry Mehrling and the Institute for New Economic Thinking. Some videos
initially included in Kernel are no longer public, but you can find most of the MOOC if
you look through the new playlists on the INET youtube channel linked above.`}</p>
    <h2 {...{
      "id": "how-this-fits-into-kernel",
      "style": {
        "position": "relative"
      }
    }}>{`How this fits into Kernel`}<a parentName="h2" {...{
        "href": "#how-this-fits-into-kernel",
        "aria-label": "how this fits into kernel permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Process mdxType="Process">
      <p>{`You cannot ask better questions unless you understand something deeply. You cannot understand
how to disrupt banks and the current financial system unless you know how we got here and what
it actually is.`}</p>
      <p>{`This series grounds value, money, speech, and incentives in very real-world, practical terms.
Literally the entire course is aimed at teaching you how to read the Financial Times and
translate it into everyday language for your friends.`}</p>
      <p>{`While not a core learning objective for Kernel, Mehrling approaches money and banking through
the issue of `}<strong parentName="p">{`liquidity`}</strong>{`, rather than solvency, and this is particularly relevant for anyone
working on DeFi.`}</p>
    </Process>
    <h2 {...{
      "id": "brief",
      "style": {
        "position": "relative"
      }
    }}>{`Brief`}<a parentName="h2" {...{
        "href": "#brief",
        "aria-label": "brief permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <blockquote>
      <p parentName="blockquote">{`"`}<strong parentName="p">{`The analytical apparatus of this course is largely balance sheets.`}</strong>{`"`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`"The two key ideas in this course are thinking of banking as a clearing system - `}<strong parentName="p">{`a payment
system`}</strong>{` - and banking as market making. Additionally, banking as advanced clearing is how we
bring in finance and forwards and futures and swaps and derivatives etc."`}</p>
    </blockquote>
    <p>{`If you watch the whole course, you'll see Mehrling use the classic T account over and over again
to explain increasingly complex concepts. You'll realise that the T account, or balance
sheet-style thinking, is just the banker's way of being able to hold in mind complementary
opposites and demand, very practically, that they balance each other out. It is a powerful, and
simple, way of understanding the flow of value anywhere in the world.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: T-accounts and thinking in terms of balance sheets are just the way bankers navigate the play of what foundational Kernel pattern?`}</p>
        <p>{`Complementary opposites.`}</p>
      </Card>
    </Flash>
    <h3 {...{
      "id": "banks-and-shadows",
      "style": {
        "position": "relative"
      }
    }}>{`Banks and shadows`}<a parentName="h3" {...{
        "href": "#banks-and-shadows",
        "aria-label": "banks and shadows permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Mehrling starts by describing a simple bank: with loans, securities and cash as its assets; and
deposits, "other borrowing", and a balancing item of "net worth" as its liabilities. If this
seems familiar and boring, stay with it, because this course is really about that strange term
"other borrowing", how it relates to the money markets, overnight lending, FedWire and shadow
banks - all the stuff you were never taught about because they didn't exist in the form they
now take, especially post-2008.`}</p>
    <p>{`Moreover, instead of focusing on a positive "Net Worth" - i.e. the solvency of banks - Mehrling
is going to be looking at that Tamil word for Chinese money: cash and the `}<strong parentName="p">{`liquidity`}</strong>{` problem,
because this is actually the constraint bankers have to face at the end of every day when they
"clear" their accounts. In many ways Mehrling's course is about `}<strong parentName="p">{`payment systems`}</strong>{` and how they
work in practice, and this is why it's so relevant in the context of peer-to-peer electronic
payment systems, or open protocols for money. In particular, understanding the current system of shadow banks will enable us to appreciate more specific ways in which it can be improved and what the potential impact of programmable money might be for how responsive the very structure of the system might become.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"We're also going to be talking about `}<strong parentName="p">{`shadow banks`}</strong>{`, `}{`[by which I mean]`}{` an entity that is
holding Residential Mortgage-Backed Securities (loans that have been turned into a kind of bond),
or Interest Rate Swaps and Credit Default Swaps to mitigate risk so that what is left is a
short-term, relatively low risk asset that can be used as collateral for borrowing in the
wholesale money market `}{`[...]`}{` So, you have money market borrowing as the liability. They're not
borrowing from you and me, but from money market mutual funds and big corporate investors. The
instruments they're using are things like Repurchase Agreements, Eurodollars (or `}<Link to="https://www.bloomberg.com/news/articles/2021-01-12/global-banks-warn-of-market-chaos-if-court-were-to-abolish-libor" mdxType="Link">{`LIBOR`}</Link>{`, or SOFR), and
Asset-Backed Commercial Paper. `}<strong parentName="p">{`The point is: this model is now the dominant one`}</strong>{`. The amount
of credit in the US that runs through this method is much larger than through traditional banks,
which is what the textbooks are all about."`}</p>
    </blockquote>
    <p>{`Shadow banking also faces the issues of solvency and liquidity, but it's more complex than in
the regular system because the issue of liquidity is really about being able to roll over the
funding for your RMBS, IRS and CDS. If the money markets freeze up, you can't roll it over and
you have to liquidate. This is what a lot of the `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=sM8JoWich98"
      }}>{`2008 financial crisis was about`}</a>{`.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: What are the two key concepts we must understand to program useful kinds of money and create a functional global system with no owners?`}</p>
        <p>{`Liquidity and the price of money.`}</p>
      </Card>
    </Flash>
    <p>{`The course looks in great detail at the Federal Reserve, which is also a bank. Mehrling begins
by discussing a 2012 Fed balance sheet and, for instance, the $852 Billion of mortgage backed
securities it was carrying then. Very pertinently, Mehrling shows how`}</p>
    <blockquote>
      <p parentName="blockquote">{`"It's just not correct to say that the Fed is 'printing money'. When it is expanding the money
supply, it is expanding `}<em parentName="p">{`both sides of the balance sheet`}</em>{`. It is a classic banking operation,
which happens at all levels of the system."`}</p>
    </blockquote>
    <p>{`Whether you agree that it `}<em parentName="p">{`should`}</em>{` happen is another story, but Mehrling takes great care to
place himself in an esteemed intellectual tradition which may make you rethink your position.
In any case:`}</p>
    <blockquote>
      <p parentName="blockquote">{`"The system has changed dramatically. When people talk about Quantitative Easing 1, QE 2, QE
3 - they're talking about the transformation of the Fed's balance sheet that I am showing you."`}</p>
    </blockquote>
    <h3 {...{
      "id": "the-4-prices-of-money",
      "style": {
        "position": "relative"
      }
    }}>{`The 4 prices of money`}<a parentName="h3" {...{
        "href": "#the-4-prices-of-money",
        "aria-label": "the 4 prices of money permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`The difference between liquidity and solvency is important. To be solvent means to possess assets in excess of liabilities, so solvency is really the ability to pay your debts. However, not all assets are “liquid”, which means that you can’t necessarily use them immediately, right this second, to pay a debt if it comes due. For instance, a house is an asset, but you can’t use it to pay for your cell phone bill, without going through a number of intervening steps. Liquidity really means “cash”: all of the value you possess which can be used to pay for debts right now. By law, banks must be solvent, hence the focus of traditional economics. However, Mehrling is making the point here that “liquidity is what really drives all the action” in FedWire, the overnight money markets, and the kind of shadow banking this course studies.`}</p>
    <p>{`To understand liquidity fully, we need to recognise that there are different prices of money. Doing so can help us appreciate what it means to have a payment system which no-one controls, because it is ultimately liquidity constraints which will define the kinds of programmable money that are useful, and the kinds that will fail.`}</p>
    <Process mdxType="Process">
      <p><strong parentName="p">{`Interest rate (future)`}</strong>{` - this is the price of money most economists focus on. It is the
price of money now in terms of money later. It's how you transform money today into money
tomorrow. However, there is a more primitive price which gets neglected in most thinking:`}</p>
      <p><strong parentName="p">{`Par (now)`}</strong>{` - the price of one money in terms of another money right now. We take it for
granted that demand deposits and cash reserves in a bank trade at par. In financial crises,
sometimes par is broken; where private money trades at a different price to central bank money.
The hybridity of our system is masked by par, but it raises the question of `}<em parentName="p">{`why does par
actually work?`}</em>{` Par is critical to the payments system - i.e. par clearing between different
states (for instance) was created by the Fed. It's not easy to create this, because it is akin
to a fixed price, which spooks economists. Just ask the people at MakerDAO.`}</p>
      <p><strong parentName="p">{`Exchange rate (foreign)`}</strong>{` - the price of domestic money in terms of foreign, or world
reserve, money. Which leads us to realise that there are, in fact, two dollars. This is well
highlighted in the `}<Link to="https://en.wikipedia.org/wiki/Triffin_dilemma" mdxType="Link">{`Triffin dilemma`}</Link>{`. `}</p>
      <p><strong parentName="p">{`Price level (commodities)`}</strong>{` - economists often reference this, imagining that the
quantity of money moves one-to-one with the price level of commodities. `}</p>
    </Process>
    <p>{`Mehrling has a unique point of view which will assist you to uncover the actual inner workings of the "tribe of bankers" and how they think about the world so that you can program money both to account for the problems they're actually solving, and go a step further towards a more equitable world in which the bottom line is not the only thing we try to optimize.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"In this course, we will not be taking sides - pro-banker vs anti-banker - we'll be trying to
figure out how the system works so that we can approach this as scientists and citizens. The
world has changed: it's a global financial world. The world of 1950's textbooks is not a world
with private capital markets, and it's also not global, because the global markets of the 19th
Century had broken down."`}</p>
    </blockquote>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: What are the four prices of money?`}</p>
        <p>{`Interest rate, par, exchange rate, and price level.`}</p>
      </Card>
    </Flash>
    <p>{`Mehrling is trying to connect the worlds of finance and banking, so we can move towards a more
holistic understanding of the overall system. His motto for doing this is simple:`}</p>
    <blockquote>
      <p parentName="blockquote">{`"`}<strong parentName="p">{`All banking is a swap of IOUs`}</strong>{`. By swap, I mean IRS, CDS and the like, but also loans.
The world of finance thinks they understand everything about `}<em parentName="p">{`swaps`}</em>{` - derivatives etc. -
but we're not going to be thinking of swaps in the terms finance people do. We'll be thinking
of them in the way money people do, in that there is a funding and liquidity piece to swaps and
that liquidity piece is what drives all the action `}{`[...]`}{` This is all about the question, 'What
does financial globalization imply for money and monetary theory'? We're trying to develop
monetary theory for the real world that is around us now."`}</p>
    </blockquote>
    <h3 {...{
      "id": "discipline-and-elasticity",
      "style": {
        "position": "relative"
      }
    }}>{`Discipline and elasticity`}<a parentName="h3" {...{
        "href": "#discipline-and-elasticity",
        "aria-label": "discipline and elasticity permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Thinking in terms of liquidity and payment systems reveals a deep tension between fiscal
`}<strong parentName="p">{`discipline`}</strong>{` and `}<strong parentName="p">{`elasticity`}</strong>{`. Mehrling introduces the image of a pyramid, made up of
different kinds of money - ranging from Federal Reserve notes at the top, to credit instruments
at the base.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/mod2-img/modern-money-pyramid.png",
        "alt": "The modern money pyramid"
      }}></img></p>
    <p>{`The critical point for our present study is that this beating heart, and the tension between
elasticity and discipline, is currently managed by a group of unelected bureaucrats at the Fed.
This is - to use Mehrling's phrase - neither a good nor a bad thing; it is a fact thing. They
attempt to control the inevitable expansions and contractions through interest rates and various
other, more contentious methods; like expanding the balance sheet and buying Residential
Mortgage-Backed Securities and similar private instruments. If you're interested in why this is
potentially not the optimal way to manage a global system,
please `}<Link to="https://www.amazon.com/Lords-Finance-Bankers-Broke-World/dp/0143116800" mdxType="Link">{`read this book`}</Link>{`. `}</p>
    <p>{`Most importantly, programmable money means that we can code our financial systems to be
responsive in ways which the current system simply cannot be. We can program stability in ways
previously unimaginable. But, we have to understand how hard that really is, why it is so hard,
and what the trade-offs in achieving it have been throughout history. This is not about
destroying central banks - Mehrling will convince you of how useful they are (preservation of
par being but one example).`}</p>
    <p>{`This is about `}<strong parentName="p">{`changing the kind of money that sits on top of the pyramid`}</strong>{` from Federal Reserve
notes to an ownerless, borderless, global, peer-to-peer ledger which holds us all equally
accountable. It is about changing the ways in which critical financial organizations can
respond to the needs of an global and interconnected system, and removing the possibility of
corruption and manipulation to the greatest extent possible with the technology we have available.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: What are the two complementary economic principles we need to be able to program if we are to create a global monetary system responsive enough to replace Federal Reserve notes?`}</p>
        <p>{`Discipline and elasticity.`}</p>
      </Card>
    </Flash>
    <h3 {...{
      "id": "further-recommendations",
      "style": {
        "position": "relative"
      }
    }}>{`Further Recommendations`}<a parentName="h3" {...{
        "href": "#further-recommendations",
        "aria-label": "further recommendations permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <Process mdxType="Process">
      <p>{`Watch `}<Link to="https://www.youtube.com/watch?v=AGkH0w9hpSQ&list=PLmtuEaMvhDZZyyAkEniNUMTTnlJj6qiKW&index=4" mdxType="Link">{`this video`}</Link>{`
for more information on the deep links between `}<strong parentName="p">{`money`}</strong>{` and `}<strong parentName="p">{`violence`}</strong>{` - in this case the
fascinating history of the Fed and the Civil War.`}</p>
      <p>{`Watch `}<Link to="https://www.youtube.com/watch?v=5Fa-9RTK8zc&list=PLmtuEaMvhDZYfVv95KDQWd8-7UrJCJ9Pm&index=6" mdxType="Link">{`these videos`}</Link>{`
to see a worked example of balance sheet-style thinking that will explain to you how credit
cards actually work (fair warning: it's a mess). This snippet will help you understand source
and use accounts, and how to think about money in both micro and macro economics.`}</p>
      <p>{`Watch `}<Link to="https://www.youtube.com/watch?v=PyTGohPHqy0&list=PLmtuEaMvhDZZh6zLsntFVt3UIgQ3VqvVv&index=2" mdxType="Link">{`this part of the course`}</Link>{`
to understand how Central Banks are like clearing houses and how the entire system can be
modelled effectively as one big bank.`}</p>
      <p>{`If you've got this far, you should just watch the whole course in your own time. I learned a lot
from the videos starting `}<Link to="https://www.youtube.com/watch?v=5oriNHybOsc&list=PLmtuEaMvhDZaBnW0RhzOOk6FO91MdsSEc&index=2" mdxType="Link">{`here`}</Link>{` -
Dealers and Liquid Security Markets.`}</p>
    </Process>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Stripe's Perspective`}</strong></p>
        <p><img parentName="p" {...{
            "src": "/images/mod2-img/stripe.png",
            "alt": "Stripe"
          }}></img></p>
        <p>{`One of the more interesting institutional perspectives on Bitcoin as the IP layer for payments. ETH - due to its generality - already takes this a step further: you can both browse a .eth site `}<strong parentName="p">{`and`}</strong>{` send payments to the `}<em parentName="p">{`same place`}</em>{`. Though this was written in 2014, we're still fairly early in the face of all the history above.`}</p>
        <Aligner center mdxType="Aligner">
    <Button to="https://stripe.com/blog/bitcoin-the-stripe-perspective" mdxType="Button">
        Read It
    </Button>
        </Aligner>
      </Box>
    </InfoBlock>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      